export default {
    //日残業時間
    async HIZANGYOUJIKAN(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    validHIZANGYOUJIKAN(settingData) {
        let data = settingData.input;
        if (data.value <= 8) data.valid = true;
        else data.valid = false;
    },
    //週残業時間
    async SHUZANGYOUJIKAN(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    validSHUZANGYOUJIKAN(settingData) {
        let data = settingData.input;
        if (data.value <= 40) data.valid = true;
        else data.valid = false;
    },
    //深夜時間帯
    async SHINYAROUDOUTIME(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: settingData.input.value
                }
            })
        settingData.input.valid = false
    },
    //残業増率
    async ZANGYOUZOURITSU(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    validZANGYOUZOURITSU(settingData) {
        let data = settingData.input;
        if (data.value >= 25) data.valid = true;
        else data.valid = false;
    },
    //休出増率
    async KYUSHUTSUZOURITSU(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    validKYUSHUTSUZOURITSU(settingData) {
        let data = settingData.input;
        if (data.value >= 35) data.valid = true;
        else data.valid = false;
    },
    //深夜労働増率
    async SHINYAROUDOUZOURITSU(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    validSHINYAROUDOUZOURITSU(settingData) {
        let data = settingData.input;
        if (data.value >= 25) data.valid = true;
        else data.valid = false;
    },
    //丸め出勤
    async MARUMESHUKKIN(settingData) {
        await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
        settingData.input.valid = false
    },
    //丸め退勤
    async MARUMETAIKKIN(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //丸め休憩START
    async MARUMEKYUKEISTART(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //丸め休憩END
    async MARUMEKYUKEIEND(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //丸め移動START
    async MARUMEIDOUSTART(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //丸め移動END
    async MARUMEIDOUEND(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //集計体制
    async SHUKEIPATTERN(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //所定と休出合算
    async SHOTEIKYUSHUTSUGASSAN(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    //移動時間を現場に加算
    async IDOUJIKANKASAN(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    async TANKAKEISANHOUHOU(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },
    async SHUKEIJITANKAKEISAN(settingData) {
        await this.$store.dispatch('post',
        {
            url: '/api/submitSettings',
            showUI: true,
            rawData:{
                codeName:settingData.codeName,
                value: Number(settingData.input.value)
            }
        })
        settingData.input.valid = false
    },    
}