<template>
    <div style="overflow-y:scroll; height:99vh;" class="my-3" v-if="settingMenu">
        <v-simple-table>
            <template v-slot:default>
                <tbody>
                    <tr v-for="(setting, index) in settingMenu" :key="index">
                        <!-- DIVIDER -->
                        <template v-if="setting.input.type === 'divider'">
                            <td colspan="3">
                                <div class="pa-5 text-h5 text-center">
                                    {{ setting.label }}
                                </div>
                            </td>
                        </template>
                        <!-- TIME PICKER -->
                        <template
                            v-else-if="setting.input.type === 'timepicker'"
                        >
                            <td>
                                <div>
                                    {{ setting.label }}
                                </div>
                            </td>
                            <td >
                                <div style="text-align: center">
                                    <v-time-picker
                                        format="24hr"
                                        v-model="setting.input.value.start"
                                        @change="setting.input.valid = true"
                                    ></v-time-picker>
                                    <v-time-picker
                                        format="24hr"
                                        v-model="setting.input.value.end"
                                        @change="setting.input.valid = true"
                                    ></v-time-picker>
                                </div>
                                <div v-if="setting.msg" class="caption red--text py-1">
                                    <pre style="  white-space: pre-line;">
                                        {{setting.msg}}
                                    </pre>
                                </div>
                            </td>
                            <td style="text-align: center">
                                <v-btn
                                    color="success"
                                    @click="setting.func(settingMenu[index])"
                                    :disabled="!setting.input.valid"
                                >
                                    確定
                                </v-btn>
                            </td>
                        </template>
                        <!--  OTHER -->
                        <template v-else>
                            <td>{{ setting.label }}</td>
                            <td class="pa-3">
                                <v-select
                                    v-if="setting.input.type === 'select'"
                                    v-model="setting.input.value"
                                    :items="setting.input.options"
                                    outlined
                                    dense
                                    :hide-details="true"
                                    @change="setting.input.valid = true"
                                >
                                </v-select>
                                <v-text-field
                                    v-if="setting.input.type === 'field'"
                                    v-model="setting.input.value"
                                    :type="setting.input.iType"
                                    outlined
                                    dense
                                    :hide-details="true"
                                    @keyup="setting.input.validation(settingMenu[index])"
                                    @change="setting.input.validation(settingMenu[index])"
                                >
                                </v-text-field>
                                <div v-if="setting.msg" class="caption red--text py-1">
                                    <pre style="  white-space: pre-line;">
                                        {{setting.msg}}
                                    </pre>
                                </div>
                            </td>
                            <td style="text-align: center">
                                <v-btn
                                    color="success"
                                    @click="setting.func(settingMenu[index])"
                                    :disabled="!setting.input.valid"
                                >
                                    確定
                                </v-btn>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import exMethods from './methods'
export default {
    data: () => ({
        settingMenu: null,
    }),
    async mounted() {
        this.generateMenu();
        this.applySavedSettings(await this.$get.getSettings())
    },
    methods: {
        applySavedSettings(settings){
            for(let i = 0 ; i<settings.length ; i++){
                for(let j = 0 ; j<this.settingMenu.length ; j++){
                    if(this.settingMenu[j].codeName === settings[i].codeName){
                        this.settingMenu[j].input.value = settings[i].value
                        break
                    }
                }
            }
        },
        generateMenu() {
            this.settingMenu = [
                {
                    label: "一般設定",
                    input: {
                        type: "divider",
                    },
                },
                {
                    label: "日残業時間(H)",
                    codeName: "HIZANGYOUJIKAN",
                    msg:`
                        法定時間外労働は一日８時間となります。
                        変えると今までの集計、残業などの計算が変わりますので、
                        必ず事前に問い合わせしてください。
                    `,
                    input: {
                        type: "field",
                        iType: "number",
                        validation: this.validHIZANGYOUJIKAN,
                        valid: false,
                        value: 8,
                    },
                    func: this.HIZANGYOUJIKAN,
                },
                {
                    label: "週残業時間(H)",
                    codeName:"SHUZANGYOUJIKAN",
                    msg:`
                        法定時間外労働は一週間４０時間です。
                        変えると今までの集計、残業などの計算が変わりますので、
                        必ず事前に問い合わせしてください。
                    `,
                    input: {
                        type: "field",
                        iType: "number",
                        validation: this.validSHUZANGYOUJIKAN,
                        valid: false,
                        value: 40,
                    },
                    func: this.SHUZANGYOUJIKAN,
                },
                {
                    label: "深夜勤務時間帯",
                    codeName:"SHINYAROUDOUTIME",
                    msg:`
                        法定深夜勤務時間帯は夜22時から翌5時までです。
                        変えると今までの集計、残業などの計算が変わりますので、
                        必ず事前に問い合わせしてください。
                    `,
                    input: {
                        type: "timepicker",
                        value: {
                            start: "22:00",
                            end: "05:00",
                        },
                        valid:false
                    },
                    func: this.SHINYAROUDOUTIME,
                },
                {
                    label: "増率設定",
                    input: {
                        type: "divider",
                    },
                },
                {
                    label: "残業増率(%)",
                    codeName: "ZANGYOUZOURITSU",
                    input: {
                        type: "field",
                        iType: "number",
                        validation: this.validZANGYOUZOURITSU,
                        valid: false,
                        value: 25,
                    },
                    func: this.ZANGYOUZOURITSU,
                },
                {
                    label: "休出増率(%)",
                    codeName:"KYUSHUTSUZOURITSU",
                    input: {
                        type: "field",
                        iType: "number",
                        validation: this.validKYUSHUTSUZOURITSU,
                        valid: false,
                        value: 35,
                    },
                    func: this.KYUSHUTSUZOURITSU,
                },
                {
                    label: "深夜労働増率(%)",
                    codeName:"SHINYAROUDOUZOURITSU",
                    input: {
                        type: "field",
                        iType: "number",
                        validation: this.validSHINYAROUDOUZOURITSU,
                        valid: false,
                        value: 25,
                    },
                    func: this.SHINYAROUDOUZOURITSU,
                },
                {
                    label: "丸め設定",
                    input: {
                        type: "divider",
                    },
                },
                {
                    label: "出勤",
                    codeName:"MARUMESHUKKIN",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMESHUKKIN,
                },
                {
                    label: "退勤",
                    codeName:"MARUMETAIKKIN",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMETAIKKIN,
                },
                {
                    label: "休憩（始）",
                    codeName:"MARUMEKYUKEISTART",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMEKYUKEISTART,
                },
                {
                    label: "休憩（終）",
                    codeName:"MARUMEKYUKEIEND",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMEKYUKEIEND,
                },
                {
                    label: "移動（始）",
                    codeName:"MARUMEIDOUSTART",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMEIDOUSTART,
                },
                {
                    label: "移動（終）",
                    codeName:"MARUMEIDOUEND",
                    input: {
                        type: "select",
                        options: [
                            { text: "なし", value: 1 },
                            { text: "5分", value: 5 },
                            { text: "10分", value: 10 },
                            { text: "15分", value: 15 },
                            { text: "30分", value: 30 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.MARUMEIDOUEND,
                },
                {
                    label: "集計設定",
                    input: {
                        type: "divider",
                    },
                },
                {
                    label: "集計体制(未)",
                    codeName:"SHUKEIPATTERN",
                    input: {
                        type: "select",
                        options: [
                            { text: "週変形", value: 1 },
                            { text: "月変形", value: 2 },
                            { text: "年変形", value: 3 },
                            { text: "フレックス", value: 4 },
                        ],
                        valid: false,
                        value: 1,
                    },
                    func: this.SHUKEIPATTERN,
                },
                {
                    label: "所定と休出合算(未)",
                    codeName:"SHOTEIKYUSHUTSUGASSAN",
                    input: {
                        type: "select",
                        options: [
                            { text: "合算しない", value: 1 },
                            { text: "合算する", value: 2 },
                        ],
                        valid: false,
                        value: 1,
                    },
                    func: this.SHOTEIKYUSHUTSUGASSAN,
                },
                {
                    label: "移動時間を現場に加算",
                    codeName:"IDOUJIKANKASAN",
                    input: {
                        type: "select",
                        options: [
                            { text: "加算しない", value: 0 },
                            { text: "前者", value: 1 },
                            { text: "後者", value: 2 },
                        ],
                        valid: false,
                        value: 0,
                    },
                    func: this.IDOUJIKANKASAN,
                },
                {
                    label: "勤務中深夜０時超えた処理方法",
                    codeName:"SHINYAKOESHORI",
                    input: {
                        type: "select",
                        options: [
                            { text: "開始日にまとめる", value: 1 },
                            { text: "翌日にまとめる", value: 2 },
                        ],
                        valid: false,
                        value: 1,
                    },
                    func: this.IDOUJIKANKASAN,
                },
                {
                    label: "単価設定",
                    input: {
                        type: "divider",
                    },
                },
                {
                    label: "単価計算方法",
                    codeName:"TANKAKEISANHOUHOU",
                    input: {
                        type: "select",
                        options: [
                            { text: "個人別", value: 1 },
                            { text: "グループ別", value: 2 },
                        ],
                        valid: false,
                        value: 1,
                    },
                    func: this.TANKAKEISANHOUHOU,
                },
                {
                    label: "集計時単価計算",
                    codeName:"SHUKEIJITANKAKEISAN",
                    input: {
                        type: "select",
                        options: [
                            { text: "常に最新の情報で計算する", value: 1 },
                            { text: "レコード終了時の情報で計算する", value: 2 },
                        ],
                        valid: false,
                        value: 1,
                    },
                    func: this.SHUKEIJITANKAKEISAN,
                },
                {
                    label: "有給設定",
                    input: {
                        type: "divider",
                    },
                },
            ];
        },
        async submitAll(){
            await this.$store.dispatch('post',
            {
                url: '/api/submitSettings',
                showUI: true,
                rawData:{
                    codeName:settingData.codeName,
                    value: Number(settingData.input.value)
                }
            })
            settingData.input.valid = false
        },
        ...exMethods
    },
};
</script>
